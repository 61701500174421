import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a07df6f4 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _2453b53d = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _f86a24b2 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _797e4c88 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _25f5d175 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _0f7e1477 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _53f3a2a1 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6bf2e816 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _1d06502c = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _a81370d4 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _0706f7f2 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _11042adc = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _7fc6aa74 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _f128f42e = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _ffa216ac = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7a8e6ee2 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _45d67160 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _a07df6f4,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _2453b53d,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _f86a24b2,
    name: "callback"
  }, {
    path: "/downloads",
    component: _797e4c88,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _25f5d175,
    name: "guests-details"
  }, {
    path: "/login",
    component: _0f7e1477,
    name: "login"
  }, {
    path: "/maintenance",
    component: _53f3a2a1,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _6bf2e816,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _1d06502c,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _a81370d4,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _0706f7f2,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _11042adc,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _7fc6aa74,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _f128f42e,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _ffa216ac,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _7a8e6ee2,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _45d67160,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
